import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseProfile } from '../../models/security/base-profile';
import { SecurityTokenStorage } from './security-token-storage';
import { LoginRemoteService } from './login-remote.service';
import { AcceptedLogin } from '../../models/security/accepted-login';
import { Observable, Subject } from 'rxjs';
import { Credential } from '../../models/security/credential';
import { RestClientService } from '../../core/services/api-access/rest-client.service';
import { SocialCredential } from '../../models/security/socialcredential';
import { I18nService } from '../../core/services/i18n.service';
import { ForgotPasswordRemoteService } from './forgot-password-remote.service';
import { LoaderService } from '../../core/services/loader.service';
import { ConfigurationRemoteService } from '../common/configuration-remote.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { UserRemoteService } from '../../core/services/remote/user/user.remote.service';
import { User } from '../../shared/models/user/user';
import { UserToken } from '../../models/security/user-token';
import { EmailVerifiedRemoteService } from './email-verified-remote.service';

/**
 * Servicio de autenticación.
 */
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  /**
   * Emite un evento cuando se ha iniciado sesión.
   */
  private OnLogin = new Subject<AcceptedLogin<BaseProfile>>();
  /**
   * Variable cierra sesión.
   */
  private OnLogout = new Subject();
  /**
   * Auth user.
   */
  private authUser;

  /**
   * Constructor.
   * @ignore
   */
  constructor(
    private loginRemoteService: LoginRemoteService,
    private securityTokenStorage: SecurityTokenStorage<UserToken>,
    private router: Router,
    private restClientService: RestClientService,
    private i18nService: I18nService,
    private forgotPasswordRemoteService: ForgotPasswordRemoteService,
    private loaderService: LoaderService,
    private emailVerifiedRemoteService: EmailVerifiedRemoteService,
    private configurationService: ConfigurationRemoteService,
    private userService: UserRemoteService,
    private storageService: StorageService
  ) {
    /**
     * Se configura el idioma del usuario.
     */
    this.setDefaultLanguage();
    /**
     * Si se caduca la sesión se redirige a la página de login.
     */
    this.securityTokenStorage
      .onSessionExpired()
      .subscribe(() => this.redirectToLogin());
  }

  /**
   * Se obtiene la url de autenticación.
   * @returns
   */
  static getAuthenticationUrl(): string {
    try {
      if (window.self !== window.top) {
        return '/login-bimserver';
      } else {
        return '/login';
      }
    } catch (e) {
      return '/login';
    }
  }

  /**
   * Se revisa si el usuario está autenticado.
   * @param redirectToLogin
   * @returns
   */
  get(redirectToLogin = true): Observable<User> {
    const subject = new Subject<any>();
    this.restClientService.get('auth-user').subscribe(
      (user) => {
        this.authUser = user;
        subject.next(user);
      },
      () => {
        this.authUser = null;
        if (redirectToLogin) {
          this.redirectToLogin();
        } else {
          subject.error('Not authenticated user');
        }
      }
    );
    return subject;
  }

  /**
   * Revisa el formulario de login.
   * @param email
   * @param password
   * @returns
   */
  login<T extends BaseProfile>(
    email: string,
    password: string
  ): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService.login<T>(new Credential(email, password)).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.authUser = acceptedLogin;
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          this.authUser = null;
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        this.authUser = null;
        subject.error(error.error);
      }
    );
    return subject;
  }

  /**
   * Cierra sesión.
   */
  logout() {
    this.authUser = null;
    const tokenObj = this.securityTokenStorage.getAcceptedLogin();
    if (tokenObj !== null) {
      this.loginRemoteService.logout().subscribe(() => {
        this.loaderService.hideLoader();
        window.location.href = `https://eyesnroad.com`;
        this.redirectToLogin();
      });
    } else {
      this.redirectToLogin();
    }
  }

  /**
   * Borra el token de la sesión.
   */
  deleteSecurityTokenInfo() {
    this.securityTokenStorage.deleteFromStorage();
    this.redirectToLogin();
  }

  /**
   * Función de login
   * @returns
   */
  onLogin(): Observable<any> {
    return this.OnLogin;
  }

  /**
   * Función de logout
   * @returns
   */
  onLogout(): Observable<any> {
    return this.OnLogout;
  }

  /**
   * Función para iniciar sesión con redes sociales.
   * @param email
   * @param authToken
   * @returns
   */
  socialLogin<T extends BaseProfile>(
    email: string,
    authToken: string
  ): Observable<AcceptedLogin<T>> {
    const subject = new Subject<AcceptedLogin<T>>();
    this.loginRemoteService
      .socialLogin<T>(new SocialCredential(email, authToken))
      .subscribe(
        (acceptedLogin) => {
          if (acceptedLogin.token) {
            this.configure(acceptedLogin);
            subject.next(acceptedLogin);
            this.OnLogin.next(acceptedLogin);
          } else {
            subject.error(acceptedLogin.error);
          }
        },
        (error) => {
          subject.error(error.error);
        }
      );
    return subject;
  }

  /**
   * Función para recuperar contraseña olvidada.
   * @param email
   * @returns
   */
  forgotPassword(email) {
    const subject = new Subject<any>();
    const emailParameter = {
      email: email.email,
      language_code: this.i18nService.getCurrentLanguage().code,
    };

    this.forgotPasswordRemoteService.forgotPassword(emailParameter).subscribe(
      (response) => {
        subject.next(response);
      },
      (error) => {
        subject.error(error);
      }
    );
    return subject;
  }

  /**
   * Función para restablecer contraseña.
   * @param parameters
   * @returns
   */
  resetPassword(parameters) {
    const subject = new Subject<any>();
    this.forgotPasswordRemoteService.resetPassword(parameters).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error);
      }
    );
    return subject;
  }

  /**
   * Comprueba si el correo electrónico está verificado.
   * @param token
   * @returns
   */
  verifiedEmail<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    const subject = new Subject<any>();
    this.emailVerifiedRemoteService.verify(token).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error.error);
      }
    );

    return subject;
  }

  /**
   * Realiza el login con el token de la sesión.
   * @param token
   * @returns
   */
  loginWithToken<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    const subject = new Subject<any>();
    this.loginRemoteService.loginWithToken(token).subscribe(
      (acceptedLogin) => {
        if (acceptedLogin.token) {
          this.configure(acceptedLogin);
          subject.next(acceptedLogin);
          this.OnLogin.next(acceptedLogin);
        } else {
          subject.error(acceptedLogin.error);
        }
      },
      (error) => {
        subject.error(error.error);
      }
    );

    return subject;
  }

  /**
   * Obtiene el idioma del usuario.
   */
  private setDefaultLanguage(): void {
    const userInfo = this.securityTokenStorage.getObjectValue();
    const defaultLanguage = userInfo
      ? userInfo.locale
      : I18nService.getSupportedLanguages()[1].code;

    if (this.storageService.get('enr-language')) {
      this.i18nService.setCurrentLanguage(
        this.storageService.get('enr-language')
      );
    } else if (userInfo) {
      this.i18nService.setCurrentLanguage(userInfo.locale);
    } else {
      const browserLang = navigator.language;
      const browserFormatted = browserLang.substr(0, 2);
      if (browserFormatted === 'en') {
        this.i18nService.setCurrentLanguage('en');
      } else if (browserFormatted === 'es') {
        this.i18nService.setCurrentLanguage('es');
      } else {
        this.i18nService.setCurrentLanguage(defaultLanguage);
      }
    }
  }

  /**
   * Redirecciona a la página de login.
   */
  private redirectToLogin(): void {
    this.securityTokenStorage.deleteFromStorage();
    this.loaderService.hideLoader();

    this.router
      .navigateByUrl(AuthenticationService.getAuthenticationUrl())
      .then(() => {
        this.OnLogout.next();
      });
  }

  /**
   * Configura el token de la sesión, lo guarda y llama a la función para establecer el idioma por defecto.
   * @param acceptedLogin
   */
  private configure<T extends BaseProfile>(
    acceptedLogin: AcceptedLogin<T>
  ): void {
    const tokenObj = {
      token: acceptedLogin.token,
      tokenExpirationDate: acceptedLogin.tokenExpirationDate,
      locale: acceptedLogin.locale,
    };
    this.securityTokenStorage.saveObject(tokenObj);
    this.setDefaultLanguage();
  }
}
